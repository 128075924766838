import { Action } from '@ngrx/store';
import { type } from '../../../util';
import {
  ILesson,
  ILessonGroupProgress,
  IQuizProgressDetails,
  ISection
} from '../../../core/services/legal-trainings/interfaces';
import { ILessonInfo } from './legal-trainings.reducer';
import { VideoQualitiesEnum } from '../../../core/services/media/interfaces';

export const ActionTypes = {
  FETCH_TRAININGS_LIST: type('[Legal trainings] fetch trainings list'),
  STORE_TRAININGS_LIST: type('[Legal trainings] store trainings list'),
  FETCH_LESSON: type('[Legal trainings] fetch lesson'),
  STORE_LESSON: type('[Legal trainings] store lesson'),
  SET_LESSON_INFO: type('[Legal trainings] set lesson info'),
  FETCH_QUIZ: type('[Legal trainings] fetch quiz'),
  STORE_QUIZ: type('[Legal trainings] store quiz'),
  CLEAR_DETAILS_VIEW: type('[Legal trainings] clear details view'),
  SET_NAVIGATION_STATE: type('[Legal trainings] lesson set navigation state'),
  FETCH_SECTION: type('[Legal trainings] fetch section'),
  STORE_SECTION_DATA: type('[Legal trainings] store section data'),
  COMPLETE_CURRENT_SECTION: type('[Legal trainings] complete current section'),
  UPDATE_VIDEO_POSITION: type('[Legal trainings] update video position'),
  UPDATE_VIDEO_QUALITY: type('[Legal trainings] update video quality'),
  SET_CURRENT_QUESTION_STATE: type('[Legal trainings] lesson question state'),
  ANSWER_QUESTION: type('[Legal trainings] answer question'),
  RESET_QUIZ: type('[Legal trainings] reset quiz'),
  UPDATE_QUESTION_STATE: type('[Legal trainings] update question state'),
  COMPLETE_TASK: type('[Legal trainings] complete task'),
};

export class FetchTrainingsList implements Action {
  public type = ActionTypes.FETCH_TRAININGS_LIST;

  constructor(public payload?: any) {}
}

export class StoreTrainingsList implements Action {
  public type = ActionTypes.STORE_TRAININGS_LIST;

  constructor(public payload: ILessonGroupProgress[]) {}
}

export class FetchLesson implements Action {
  public type = ActionTypes.FETCH_LESSON;

  constructor(public payload: string | number) {}
}

export class StoreLesson implements Action {
  public type = ActionTypes.STORE_LESSON;

  constructor(public payload: ILesson | null) {}
}

export class SetLessonInfo implements Action {
  public type = ActionTypes.SET_LESSON_INFO;

  constructor(public payload: ILessonInfo) {}
}

export class FetchQuiz implements Action {
  public type = ActionTypes.FETCH_QUIZ;

  constructor(public payload: string | number) {}
}

export class StoreQuiz implements Action {
  public type = ActionTypes.STORE_QUIZ;

  constructor(public payload: IQuizProgressDetails | null) {}
}

export class SetNavigationState implements Action {
  public type = ActionTypes.SET_NAVIGATION_STATE;

  constructor(public payload: boolean) {}
}

export class ClearDetailsView implements Action {
  public type = ActionTypes.CLEAR_DETAILS_VIEW;

  constructor(public payload?: any) {}
}

export class FetchSection implements Action {
  public type = ActionTypes.FETCH_SECTION;

  constructor(public payload: string | number) {}
}

export class StoreSection implements Action {
  public type = ActionTypes.STORE_SECTION_DATA;

  constructor(public payload: ISection | null) {}
}

export class CompleteCurrentSection implements Action {
  public type = ActionTypes.COMPLETE_CURRENT_SECTION;

  constructor(public payload?: any) {}
}

export class UpdateVideoPosition implements Action {
  public type = ActionTypes.UPDATE_VIDEO_POSITION;

  constructor(public payload: number) {}
}

export class UpdateCurrentVideoQuality implements Action {
  public type = ActionTypes.UPDATE_VIDEO_QUALITY;

  constructor(public payload: VideoQualitiesEnum) {}
}

export class SetQuestionState implements Action {
  public type = ActionTypes.SET_CURRENT_QUESTION_STATE;

  constructor(public payload: {
    answerIndex?: number | null,
    isAnsweredCorrect?: boolean | null
  }) {}
}

export class AnswerQuestion implements Action {
  public type = ActionTypes.ANSWER_QUESTION;

  constructor(public payload: {
    questionId: number
  }) {}
}

export class ResetQuiz implements Action {
  public type = ActionTypes.RESET_QUIZ;

  constructor(public payload: number | string) {}
}

export class CompleteLegalTrainings implements Action {
  public type = ActionTypes.COMPLETE_TASK;

  constructor(public payload?: any) {}
}

export class UpdateQuestionState implements Action {
  public type = ActionTypes.UPDATE_QUESTION_STATE;

  constructor(public payload: {
    questionId: number | string,
    isCorrect: boolean
  }) {}
}

export type TypeAction =
  StoreTrainingsList |
  StoreLesson |
  SetLessonInfo |
  SetNavigationState |
  ClearDetailsView |
  FetchSection |
  StoreSection |
  CompleteCurrentSection |
  UpdateVideoPosition |
  UpdateCurrentVideoQuality |
  SetQuestionState |
  AnswerQuestion |
  ResetQuiz |
  CompleteLegalTrainings |
  UpdateQuestionState |
  StoreQuiz;
