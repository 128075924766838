import {
  ILessonGroupProgress,
  ILessonProgress, IQuizAnswer, IQuizProgress,
  IQuizQuestion,
  IVideoSection
} from '../../../core/services/legal-trainings/interfaces';

export interface IQuizAnswerUpdated extends IQuizAnswer {
  questionId: string | number;
}

export type questionSearchType = 'isAnsweredCorrectly' | 'hasAttempted';

export const isAllSectionsCompleted = (sections: IVideoSection[]): boolean =>
  sections.every((section) => section.isComplete);

export const isPreviousVideoComplete = (sections: IVideoSection[], index: number): boolean => {
  if (!index) {
    return true;
  }

  return sections[index - 1].isComplete;
};

export const getFirstNotOfSearchTypeQuestion = (type: questionSearchType) =>
  (questions: IQuizQuestion[]): IQuizQuestion => {
    const isEverySatisfy = questions.every((question) => question[type]);

    if (isEverySatisfy) {
      return questions[0];
    }

    return questions.reduce((acc, item) => acc[type] ? item : acc);
  };

export const calculateScore = (correct, total) => Math.round((correct / total) * 100);

export const updateAnswerWithQuestionId = (questionId) => (a: IQuizAnswer): IQuizAnswerUpdated =>
  Object.assign({}, a, { questionId });

export const updateQuestionAnswers = (q: IQuizQuestion) => Object.assign({}, q, {
  answerOptions: q.answerOptions.map(updateAnswerWithQuestionId(q.questionId))
});

export const getIsSectionsCompleted = (sectionCount, sectionsCompleted) => sectionCount ?
    sectionsCompleted === sectionCount :
    true;

export const getIsQuizProgressPassed = (quizProgress: IQuizProgress) => {
  const {
    questionCount,
    correctAnswers,
    passingScore,
    questionsAttempted
  } = quizProgress;

  return questionCount ?
    questionCount === questionsAttempted &&
    calculateScore(correctAnswers, questionCount) >= passingScore :
    true;
};

export const checkLessonCompletion = (lesson: ILessonProgress) => {
  const {
    sectionCount,
    sectionsCompleted,
    quizProgress
  } = lesson;

  return getIsSectionsCompleted(sectionCount, sectionsCompleted) &&
    getIsQuizProgressPassed(quizProgress);
};

export const checkGroupCompletion = ({ lessons }: ILessonGroupProgress) =>
  lessons.every(checkLessonCompletion);
