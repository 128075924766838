import { createSelector } from '@ngrx/store';
import { IRootState } from '../../../store/index';
import {
  ActionTypes,
  TypeAction
} from './legal-trainings.actions';
import {
  ILesson,
  ILessonGroupProgress,
  ILessonProgress, IQuizAnswer,
  IQuizProgressDetails,
  IQuizQuestion
} from '../../../core/services/legal-trainings/interfaces';
import { calculateScore, checkGroupCompletion, updateQuestionAnswers } from './helpers';

export interface ILessonInfo {
  title: string;
  description: string;
}

export interface ISectionData {
  sectionId: number;
  sectionTitle: string;
  sectionDescription: string;
  videos: {
    hd?: string;
    sd?: string;
  };
  currentVideoUrl: string;
  qualityList: string[];
  currentVideoPosition: number;
  currentVideoQuality: string;
}

export interface IQuizResult {
  isPassed: boolean;
  score: number;
  correctAnswers: number;
  questionsCount: number;
}

export interface ICurrentQuestion {
  isAnsweredCorrect: boolean | null;
  answerIndex: number | null;
}

export interface IState {
  lessonGroups: ILessonGroupProgress[] | null;
  lesson: ILesson | null;
  lessonInfo: ILessonInfo;
  navigationOpen: boolean;
  quizProgressDetails: IQuizProgressDetails | null;
  currentSectionData: ISectionData | null;
  currentQuestion: ICurrentQuestion;
}

const initialState: IState = {
  lessonGroups: null,
  lesson: null,
  lessonInfo: {
    title: '',
    description: ''
  },
  navigationOpen: false,
  quizProgressDetails: null,
  currentSectionData: null,
  currentQuestion: {
    isAnsweredCorrect: null,
    answerIndex: null,
  }
};

export function legalTrainingsReducer(state: IState = initialState, action: TypeAction): IState {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.STORE_TRAININGS_LIST: {
      return Object.assign({}, state, { lessonGroups: payload });
    }
    case ActionTypes.STORE_LESSON: {
      return Object.assign({}, state, { lesson: payload });
    }
    case ActionTypes.SET_LESSON_INFO: {
      return Object.assign({}, state, { lessonInfo: payload });
    }
    case ActionTypes.STORE_QUIZ: {
      return Object.assign({}, state, { quizProgressDetails: payload });
    }
    case ActionTypes.SET_NAVIGATION_STATE: {
      return Object.assign({}, state, { navigationOpen: payload });
    }
    case ActionTypes.SET_CURRENT_QUESTION_STATE: {
      const { currentQuestion } = state;
      const result = Object.assign({}, currentQuestion, payload);

      return Object.assign({}, state, { currentQuestion: result });
    }
    case ActionTypes.UPDATE_QUESTION_STATE: {
      const { quizProgressDetails } = state;
      const { questions } = quizProgressDetails;

      const updatedQuestions = questions.map((q) => {
        if (q.questionId === payload.questionId) {
          return Object.assign({}, q, { isAnsweredCorrectly: payload.isCorrect });
        }

        return q;
      });

      const result = Object.assign({},
        quizProgressDetails,
        { questions: updatedQuestions }
      );

      return Object.assign({}, state, { quizProgressDetails: result });
    }
    case ActionTypes.CLEAR_DETAILS_VIEW: {
      return Object.assign({}, state, {
        lesson: null,
        quizProgressDetails: null
      });
    }
    case ActionTypes.STORE_SECTION_DATA: {
      return Object.assign({}, state, { currentSectionData: payload });
    }
    case ActionTypes.UPDATE_VIDEO_POSITION: {
      const result = Object.assign({}, state.currentSectionData, {
        currentVideoPosition: payload
      });

      return Object.assign({}, state, { currentSectionData: result });
    }
    case ActionTypes.UPDATE_VIDEO_QUALITY: {
      const result = Object.assign({}, state.currentSectionData, {
        currentVideoQuality: payload,
        currentVideoUrl: state.currentSectionData.videos[payload]
      });

      return Object.assign({}, state, { currentSectionData: result });
    }
    case ActionTypes.COMPLETE_CURRENT_SECTION: {
      const { lesson, currentSectionData: { sectionId: currentSectionId } } = state;

      lesson.sections.forEach((section) => {
        if (section.sectionId === currentSectionId) {
          section.isComplete = true;
        }
      });

      return Object.assign({}, state, { lesson });
    }
    default: {
      return state;
    }
  }
}

export const getTrainingsList = createSelector(
  ({ legalTrainings }: IRootState): IState => legalTrainings,
  ({ lessonGroups }: IState): ILessonGroupProgress[] => lessonGroups
);

export const getIsLessonExist = (id) => createSelector(
  ({ legalTrainings }: IRootState): IState => legalTrainings,
  ({ lessonGroups }: IState): boolean =>
    lessonGroups.some((lessonGroup: ILessonGroupProgress) =>
      lessonGroup.lessons.some((lesson: ILessonProgress) => lesson.lessonId === id)
    )
);

export const getCurrentLesson = createSelector(
  ({ legalTrainings }: IRootState): IState => legalTrainings,
  ({ lesson }: IState): ILesson => lesson
);

export const getIsNavigationOpen = createSelector(
  ({ legalTrainings }: IRootState): IState => legalTrainings,
  ({ navigationOpen }: IState): boolean => navigationOpen
);

export const getQuizQuestions = createSelector(
  ({ legalTrainings }: IRootState): IState => legalTrainings,
  ({ quizProgressDetails }: IState): IQuizQuestion[] => quizProgressDetails ?
    quizProgressDetails.questions.map(updateQuestionAnswers) : []
);

export const getQuizDetails = createSelector(
  ({ legalTrainings }: IRootState): IState => legalTrainings,
  ({ quizProgressDetails }: IState): IQuizProgressDetails => quizProgressDetails
);

export const getCurrentSectionData = createSelector(
  ({ legalTrainings }: IRootState): IState => legalTrainings,
  ({ currentSectionData }: IState) => currentSectionData
);

export const getCurrentSectionId = createSelector(
  ({ legalTrainings }: IRootState): IState => legalTrainings,
  ({ currentSectionData }: IState) => currentSectionData.sectionId
);

export const getLessonInfo = createSelector(
  ({ legalTrainings }: IRootState): IState => legalTrainings,
  ({ lessonInfo }: IState): ILessonInfo => lessonInfo
);

export const getIsSectionAvailable = (sectionId) => createSelector(
  ({ legalTrainings }: IRootState): IState => legalTrainings,
  ({ lesson }: IState): boolean => lesson.sections.some((section, index) => {
      const previousSection = lesson.sections[index - 1];

      return section.sectionId === +sectionId && (!previousSection || previousSection.isComplete);
    })
);

export const getNextSectionId = createSelector(
  ({ legalTrainings }: IRootState): IState => legalTrainings,
  ({ lesson: { sections }, currentSectionData }: IState): string | number => {
    const { sectionId } = currentSectionData;
    const sectionIndex = sections.map((section) => section.sectionId).indexOf(sectionId);
    const nextSection = sections[sectionIndex + 1];

    return nextSection ? nextSection.sectionId : null;
  }
);

export const getFirstUncompletedSectionId = createSelector(
  ({ legalTrainings }: IRootState): IState => legalTrainings,
  ({ lesson: { sections } }: IState): string | number => {
    const uncompletedSection = sections.find((section) => !section.isComplete);

    return uncompletedSection ? uncompletedSection.sectionId : sections.slice(-1)[0].sectionId;
  }
);

export const getCurrentQuestionState = createSelector(
  ({ legalTrainings }: IRootState): IState => legalTrainings,
  ({ currentQuestion }: IState): ICurrentQuestion => currentQuestion
);

export const getAnswer = (questionId) => createSelector(
  ({ legalTrainings }: IRootState): IState => legalTrainings,
  ({ currentQuestion, quizProgressDetails }: IState): IQuizAnswer => {
    return quizProgressDetails.questions
      .find((q) => q.questionId === questionId)
      .answerOptions[currentQuestion.answerIndex];
  }
);

export const getIsAllAttempted = createSelector(
  ({ legalTrainings }: IRootState): IState => legalTrainings,
  ({ quizProgressDetails: { questions } }: IState): boolean =>
    questions.every((q) => q.hasAttempted)
);

export const getQuizResult = createSelector(
  ({ legalTrainings }: IRootState): IState => legalTrainings,
  ({ quizProgressDetails }: IState): IQuizResult => {
    const { questions, passingScore } = quizProgressDetails;
    const correct = questions.filter((q) => q.isAnsweredCorrectly).length;
    const score = calculateScore(correct, questions.length);

    return {
      score,
      isPassed: score >= passingScore,
      correctAnswers: correct,
      questionsCount: questions.length
    };
  }
);

export const isAllTrainingsCompleted = createSelector(
  ({ legalTrainings }: IRootState): IState => legalTrainings,
  ({ lessonGroups }: IState): boolean => lessonGroups.every(checkGroupCompletion)
);
